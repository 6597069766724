<template>
  <div class="admin-chat-card">

    <div class="person-info">
      <div class="data">
        <img v-if="user.image && showImage" :src=user.image :alt=user.name @error="onError" class="person-img">
        <div v-else class="person-img">
     <span>
        <d-person-icon/>
     </span>
        </div>

        <div class="col">
          <div class="name">{{ user.name }}</div>
        </div>
      </div>
      <wameed-btn
          variant="gray"
          size="sm"
          :title="$t('btn.add')"
          classes="text-book-18  rounded-12 w-unset px-4 success-btn"
          block
          @onClick="()=>addClick(user)"
      />
    </div>
  </div>
</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {WameedBtn},
  props: {
    user: {}
  },
  data() {
    return {
      showImage: true,
    }
  },

  methods: {
    onError() {
      this.showImage = false;
    },

    addClick(user) {
      this.$emit('addClick', user);
    },


  }
}
;
</script>
