var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content chat-page"},[_c('page-header',{attrs:{"title":_vm.$t('chats.title')}}),_c('div',[_c('chat-window',{attrs:{"height":"calc(100vh - 180px)","current-user-id":_vm.currentUserId,"load-first-room":true,"room-actions":[],"menu-actions":[
            {
              name: 'addUser',
              title: _vm.$t('chats.actions.add_user'),
            },
            {
              name: 'closeChat',
              title: _vm.$t('chats.actions.close_chat'),
            } ],"accepted-files":'image/png, image/jpeg',"room-id":_vm.openedRoomId,"rooms":_vm.getRooms,"rooms-loaded":_vm.roomsLoaded,"loading-rooms":_vm.loadingRooms,"messages":_vm.getRoomMessages,"messages-loaded":_vm.messagesLoaded,"room-info-enabled":false,"show-add-room":false,"emojis-suggestion-enabled":false,"show-reaction-emojis":false,"show-emojis":false,"message-selection-actions":[],"message-actions":[],"show-new-messages-divider":false,"show-audio":false,"text-messages":{
          TYPE_MESSAGE: _vm.$t('chats.enter_message'),
          SEARCH: _vm.$t('chats.search'),
        }},on:{"fetch-messages":_vm.onFetchMessages,"fetch-more-rooms":_vm.fetchMoreRooms,"menu-action-handler":_vm.menuActionHandler,"send-message":_vm.sendMessage},scopedSlots:_vm._u([{key:"room-list-item",fn:function(ref){
        var room = ref.room;
return [_c('div',{staticClass:"vac-room-container"},[_c('div',{staticClass:"vac-avatar",style:(("background-image: url(" + (room.roomName) + ");"))}),_c('div',{staticClass:"vac-name-container vac-text-ellipsis ml-3"},[_c('div',{staticClass:"vac-title-container"},[_c('div',{staticClass:"vac-room-name vac-text-ellipsis"},[_vm._v(" "+_vm._s(room.roomName)+" "),(room.closedById)?_c('span',{staticClass:"text-danger"},[_vm._v("("+_vm._s(_vm.$t('common.closed'))+")")]):_vm._e()]),_c('div',{staticClass:"vac-text-date"},[_vm._v(_vm._s(room.lastMessage.timestamp))])]),_c('div',{staticClass:"vac-text-last"},[_c('div',{staticClass:"vac-format-message-wrapper vac-text-ellipsis"},[_c('div',{staticClass:"vac-text-ellipsis"},[_c('div',{staticClass:"vac-format-container"},[_c('span',{staticClass:"vac-text-ellipsis"},[_c('span',[_vm._v(_vm._s(room.lastMessage.content))])])])])]),_c('div',{staticClass:"vac-room-options-container"},[(room.unreadCount)?_c('div',{staticClass:"vac-badge-counter vac-room-badge"},[_vm._v(" "+_vm._s(room.unreadCount))]):_vm._e()])])])])]}},{key:"message",fn:function(ref){
        var message = ref.message;
return [(message.type!='add' && message.type!='close')?[(message.senderId  !== _vm.currentUserId)?_c('div',{staticClass:"vac-avatar vac-avatar-current",style:(("background-image: url(" + (message.avatar) + ");"))}):_vm._e(),_c('div',{staticClass:"vac-message-container ",class:message.senderId  === _vm.currentUserId?'':'vac-messsage-container-offset'},[_c('div',{staticClass:"vac-message-card ",class:message.senderId  === _vm.currentUserId?'':'vac-message-current'},[_vm._l((message.files),function(file){return _c('div',{staticClass:"vac-message-files-container"},[_c('div',[_c('div',{staticClass:"vac-message-file-container"},[_c('div',{staticClass:"vac-message-image-container"},[_c('a',{staticClass:"vac-message-image",style:(("background-image: url(" + (file.url) + "); max-height: 232px;display:block")),attrs:{"href":file.url,"target":"_blank"}})])])]),_c('div',{staticClass:"vac-format-message-wrapper"},[_c('div',{})])])}),(message.senderId  !== _vm.currentUserId)?_c('div',{staticClass:"vac-text-username"},[_c('span',[_vm._v(" "+_vm._s(message.username)+" ")])]):_vm._e(),_c('div',{staticClass:"vac-format-message-wrapper"},[_c('div',{},[_c('div',{staticClass:"vac-format-container"},[_c('span',{},[_c('span',[_vm._v(_vm._s(message.content))])])])])]),_c('div',{staticClass:"vac-text-timestamp"},[_c('span',[_vm._v(_vm._s(message.timestamp))])]),_c('div',{staticClass:"vac-message-actions-wrapper"},[_c('div',{staticClass:"vac-options-container",staticStyle:{"display":"initial","width":"45px"}},[_c('span')])])],2)])]:(message.type=='add')?[_c('div',{staticClass:"chat-action-container"},[_c('div',{staticClass:"chat-action"},[_vm._v(" "+_vm._s(message.content.replace('+', ' ' + _vm.$t('chats.actions.added') + ' '))+" ")])])]:(message.type=='close')?[_c('div',{staticClass:"chat-action-container"},[_c('div',{staticClass:"chat-action"},[_vm._v(" "+_vm._s(message.content + ' ' + _vm.$t('chats.actions.closed'))+" ")])])]:_vm._e()]}},{key:"room-header-info",fn:function(ref){
        var room = ref.room;
return [_c('div',{staticClass:"vac-text-ellipsis"},[_c('div',{staticClass:"vac-room-name vac-text-ellipsis"},[_vm._v(" "+_vm._s(room.roomName))]),_c('div',{staticClass:"vac-room-info vac-text-ellipsis"})])]}}])},[_c('div',{staticClass:"rotate-icon-90",attrs:{"slot":"send-icon"},slot:"send-icon"},[_c('chat-send-icon',{staticStyle:{"width":"40px","height":"40px"}})],1),_c('div',{attrs:{"slot":"messages-empty"},slot:"messages-empty"},[_c('wameed-no-data',{staticStyle:{"height":"calc(100vh - 400px)"},attrs:{"title":_vm.$t('chats.no_messages.title'),"sub-title":_vm.$t('chats.no_messages.sub-title')}})],1),_c('div',{attrs:{"slot":"rooms-empty"},slot:"rooms-empty"},[_c('div',[_vm._v(_vm._s(_vm.$t('chats.no_rooms.sub-title')))])]),_c('div',{attrs:{"slot":"no-room-selected"},slot:"no-room-selected"},[_c('wameed-no-data',{staticStyle:{"height":"calc(100vh - 180px)"},attrs:{"title":_vm.$t('chats.no_messages.title'),"sub-title":_vm.$t('chats.no_messages.sub-title')}})],1)])],1),_c('admin-list-modal',{attrs:{"visible":_vm.showAdminList},on:{"close":function($event){_vm.showAdminList = false},"addClick":_vm.addUserToRoom}}),_c('warning-modal',{attrs:{"variant":"main","iconName":"o-toggle-icon","visible":_vm.showCloseModal,"title":_vm.$t('chats.actions.close_chat'),"subTitle":_vm.$t('chats.actions.close_subtitle'),"btnTitle":_vm.$t('chats.actions.close_btn')},on:{"close":function($event){_vm.showCloseModal = false},"submitAction":_vm.closeRoom}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }